import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

import { IProduct } from '@vb/shared/interfaces';

import { environment } from '../environments/environment';
import { AppCanonicalService } from './app-canonical.service';
import { AppHelperService } from './app-helper.service';

const BASE_SEPARATOR = ' - ';
const BASE_TITLE = environment.app.name;

export interface IMetaConfig {
    title?: string;
    category?: string;
    description?: string;
    image?: string;
    url?: string;
}

@Injectable()
export class AppMetaService {
    constructor(
        private _appHelper: AppHelperService,
        private _meta: Meta,
        private _title: Title,
        private _canonical: AppCanonicalService
    ) {}

    update(config: IMetaConfig) {
        this._setTitle(config);
        this._setDescription(config);
        this._setImage(config);
        this._setUrl(config.url);
    }

    setProduct(product: IProduct) {
        if (!product) {
            return;
        }

        if (!this._meta.getTag('property="product:retailer_item_id"')) {
            this._meta.addTag({
                property: 'product:retailer_item_id'
            });
        }
        if (!this._meta.getTag('property="product:condition"')) {
            this._meta.addTag({
                property: 'product:condition'
            });
        }
        if (!this._meta.getTag('property="product:price:amount"')) {
            this._meta.addTag({
                property: 'product:price:amount'
            });
        }
        if (!this._meta.getTag('property="product:price:currency"')) {
            this._meta.addTag({
                property: 'product:price:currency'
            });
        }
        if (!this._meta.getTag('property="product:brand"')) {
            this._meta.addTag({
                property: 'product:brand'
            });
        }

        this._meta.updateTag({ content: product.productId }, 'property="product:retailer_item_id"');
        this._meta.updateTag({ content: 'new' }, 'property="product:condition"');

        if (product.price) {
            this._meta.updateTag(
                { content: parseFloat(product.price?.toString()).toFixed(2) },
                'property="product:price:amount"'
            );
        }

        this._meta.updateTag({ content: 'SEK' }, 'property="product:price:currency"');

        if (product.producer && product.producer.name) {
            this._meta.updateTag({ content: product.producer.name }, 'property="product:brand"');
        }
    }

    private _setTitle(config: IMetaConfig): void {
        let title = BASE_TITLE;

        if (config.category) {
            title = config.category + BASE_SEPARATOR + title;
        }

        if (config.title) {
            title = config.title + BASE_SEPARATOR + title;
        }

        this._title.setTitle(title);
        this._meta.updateTag({ content: title }, 'property="og:title"');
        this._meta.updateTag({ content: title }, 'name="twitter:title"');
    }

    private _setDescription(config: IMetaConfig): void {
        let description = '';

        if (config.description) {
            description = config.description;
        }

        this._meta.updateTag({ content: description }, 'name="description"');
        this._meta.updateTag({ content: description }, 'property="og:description"');
        this._meta.updateTag({ content: description }, 'name="twitter:description"');
    }

    private _setImage(config: IMetaConfig): void {
        let image = 'https://vinborsen.se/assets/vinborsen-banner-1200x630.png';

        if (config.image) {
            image = config.image;
        }

        this._meta.updateTag({ content: image }, 'property="og:image"');
        this._meta.updateTag({ content: image }, 'name="twitter:image"');
    }

    private _setUrl(forcedUrl?: string): void {
        const url = forcedUrl || this._appHelper.getCurrentUrl();

        this._canonical.setUrl(url);
        this._meta.updateTag({ content: url }, 'property="og:url"');
    }
}
