import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

const CANONICAL_TAG_ID = '_vb-canonical';

@Injectable({
    providedIn: 'root'
})
export class AppCanonicalService {
    constructor(@Inject(DOCUMENT) private dom: Document) {}

    setUrl(url?: string) {
        const canURL = url || this.dom.URL;

        let el: HTMLElement = this.dom.getElementById(CANONICAL_TAG_ID);

        if (!el) {
            el = this.dom.createElement('link');
            el.setAttribute('id', CANONICAL_TAG_ID);
            el.setAttribute('rel', 'canonical');
            this.dom.head.appendChild(el);
        }

        el.setAttribute('href', canURL);
    }
}
