import { DOCUMENT, isPlatformServer, Location } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';

import { Response } from 'express';

import { IProduct } from '@vb/shared/interfaces';

import { environment } from '../environments/environment';
import { RESPONSE } from '../express.tokens';

export interface IAppImageThumbnails {
    '1x1'?: string;
    '4x3'?: string;
    '16x9'?: string;
    facebook?: string;
}

@Injectable({ providedIn: 'root' })
export class AppHelperService {
    constructor(
        @Optional() @Inject(RESPONSE) private response: Response,
        @Inject(PLATFORM_ID) private platformId: object,
        @Inject(DOCUMENT) private document: Document,
        private _location: Location,
        private _router: Router
    ) {}

    redirect(path: string[]) {
        if (isPlatformServer(this.platformId)) {
            this.response.location(path.join('/'));
            this.response.status(301);
        } else {
            this._router.navigate(path, {
                replaceUrl: true,
                queryParamsHandling: 'preserve'
            });
        }
    }

    redirect404() {
        this._router.navigate(['/404'], {
            skipLocationChange: true,
            queryParamsHandling: 'preserve'
        });
    }

    getCurrentPath() {
        return this._location.path();
    }

    getCurrentLocation() {
        const hostname = this.document.location.hostname;

        let location = environment.locations.find((l) => l.hostname === hostname);

        if (!location) {
            location = environment.locations.find((l) => !!l.default);
        }

        return location;
    }

    getCurrentBaseUrl() {
        const location = this.getCurrentLocation();

        const base = `${location.protocol}://${location.hostname}`;

        return location.port ? base + ':' + location.port : base;
    }

    getCurrentUrl(includeSearch = false): string {
        const { prefix } = this.getCurrentLocation();

        const path = this.getCurrentPath();
        const base = this.getCurrentBaseUrl();
        const url = new URL(`${prefix}${path}`, base);

        if (!includeSearch) {
            url.search = '';
        }

        return url.toString();
    }

    generateProductTitle(product: IProduct): string {
        let title = [product.title, product.subtitle, product.vintage]
            .filter((p) => !!p)
            .join(', ');

        if (product.isDiscounted && product.priceDiscount) {
            title += ` (prissänkt med ${Math.round(product.priceDiscount * 100)}%)`;
        }

        return title;
    }

    generateProductDescription(product: IProduct): string {
        let description = '';

        const descriptionCategory = product.category
            ? product.category.level2 || product.category.level1
            : 'Dryck';
        const descriptionOrigin = [];

        if (product.origin && product.origin.level1) {
            descriptionOrigin.push(product.origin.level1);
        }
        if (product.origin && product.origin.country) {
            descriptionOrigin.push(product.origin.country);
        }
        description = `${descriptionCategory} från ${descriptionOrigin.join(', ')}`;

        if (product.details && product.details.taste) {
            description += ` - ${product.details.taste}`;
        }

        return description;
    }

    generateProductCategory(product: IProduct): string | undefined {
        if (!product.category?.level1) {
            return undefined;
        }
        return [product.category.level2, product.category.level1].filter((v) => !!v).join(', ');
    }

    generateProductImage(product: IProduct): IAppImageThumbnails {
        if (!product.image) {
            return {
                '1x1': '',
                '4x3': '',
                '16x9': '',
                facebook: ''
            };
        }

        return {
            '1x1':
                product.image +
                '?fit=fill&w=1200&h=1200&pad=40&bg=fff&mark64=aHR0cHM6Ly92aW5ib3JzZW4uc2UvYXNzZXRzL3ZpbmJvcnNlbi1sb2dvLnBuZw&mark-h=60',
            '4x3':
                product.image +
                '?fit=fill&w=1200&h=900&pad=40&bg=fff&mark64=aHR0cHM6Ly92aW5ib3JzZW4uc2UvYXNzZXRzL3ZpbmJvcnNlbi1sb2dvLnBuZw&mark-h=60',
            '16x9':
                product.image +
                '?fit=fill&w=1200&h=675&pad=40&bg=fff&mark64=aHR0cHM6Ly92aW5ib3JzZW4uc2UvYXNzZXRzL3ZpbmJvcnNlbi1sb2dvLnBuZw&mark-h=60',
            facebook:
                product.image +
                '?fit=fill&w=1200&h=630&pad=40&bg=fff&mark64=aHR0cHM6Ly92aW5ib3JzZW4uc2UvYXNzZXRzL3ZpbmJvcnNlbi1sb2dvLnBuZw&mark-h=60'
        };
    }

    generateSanityImage(url: string): IAppImageThumbnails {
        if (!url) {
            return {
                '1x1': '',
                '4x3': '',
                '16x9': '',
                facebook: ''
            };
        }

        return {
            '1x1': url + '?fit=crop&w=1200&h=1200',
            '4x3': url + '?fit=crop&w=1200&h=900',
            '16x9': url + '?fit=crop&w=1200&h=675',
            facebook: url + '?fit=crop&w=1200&h=630'
        };
    }
}
